<template>
  <dashboard-card
    class="dashboard-offers tw-relative"
  >
    <template #header-title>
      <div
        class="tw-w-full tw-flex tw-flex-col md:tw-flex-row md:tw-items-center md:tw-justify-between"
      >
        <h2
          class="dashboard-card__header__title tw-mb-4 md:tw-mb-2"
          data-test="title"
        >
          <span
            v-text="$t('dashboard.titles.offers')"
            class="tw-mb-1"
            data-test="content"
          />
        </h2>

        <ui-button
          :rounded="false"
          variant="info"
          data-test="compare"
          v-matomo="{
            click: { category: 'Dashboard', action: 'Click Compare Offers' }
          }"
          @click="compare"
        >
          {{ $t('dashboard.buttons.offers.compare') }}
        </ui-button>
      </div>
    </template>

    <div class="tw-flex tw-flex-col md:tw-flex-row">
      <dashboard-offers-item
        v-for="(offer, k) in offers"
        :key="k"
        :offer-type="k"
        :icon="offer.icon"
        :title="offer.title"
        :content="offer.content"
        :data-test="`${k}-item`"
        class="tw-w-full tw-mb-4"
        @learn-more="learnMore(k)"
      />
    </div>
  </dashboard-card>
</template>

<script>
  import { EventBus } from '@/services/EventBus'
  import { offers } from '@/../config/chronoinfos'

  import DashboardCard from './../DashboardCard'
  import DashboardOffersItem from './_subs/DashboardOffersItem'

  /**
   * @module component - DashboardOffers
   */
  export default {
    name: 'DashboardOffers',
    components: {
      DashboardCard,
      DashboardOffersItem
    },
    data () {
      return {
        offers: {
          chronotruck: {
            icon: require('@/assets/img/icons/truck-classic.svg'),
            title: offers.chronotruck,
            content: this.$t('dashboard.paragraphs.offers.chronotruck')
          },
          pallet_network: {
            icon: require('@/assets/img/icons/truck-network.svg'),
            title: offers.pallet_network,
            content: this.$t('dashboard.paragraphs.offers.pallet_network')
          },
          corridor: {
            icon: require('@/assets/img/icons/truck-lines.svg'),
            title: offers.corridor,
            content: this.$t('dashboard.paragraphs.offers.corridor')
          }
        }
      }
    },
    methods: {
      /**
       * @function learnMore
       * @param {string} priceType
       */
      learnMore (priceType) {
        if (this.$matomo) {
          this.$matomo.trackEvent('Dashboard', 'Click Learn More', priceType)
        }

        EventBus.$emit('dashboard:dialogs:open-dialog', {
          dialog: 'learnMore',
          priceType
        })
      },
      /**
       * @function compare
       */
      compare () {
        EventBus.$emit('dashboard:dialogs:open-dialog', {
          dialog: 'compare'
        })
      }
    }
  }
</script>

<style lang="scss">

  .dashboard-offers .dashboard-card__header {
    margin-bottom: 20px;
  }

  .dashboard-offers__content p {
    color: $secondary-text;
    margin-bottom: 0;
  }

</style>

<style lang="scss" scoped>

  .dashboard-offers .dashboard-offers-item {
    @media only screen and (min-width: $breakpoint-tablet) {
      width: 33.33%;
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: 1.375rem;
      }
    }
  }

</style>
