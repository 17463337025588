<template>
  <div class="dashboard-express-quotation-form">
    <ctk-input-address
      v-model="formData.pickupString"
      :direction="'pickup'"
      :provider="provider"
      :disabled-countries="disabledCountries('pickup')"
      :label="$t('dashboard.labels.quotation.pickup_address')"
      :error="errors.pickup"
      :country.sync="countries.pickup"
      id="pickup"
      data-test="pickup-address"
      class="tw-mb-2"
      @click.native="initiate"
      @autocomplete="v => updateAddress('pickup', v)"
      @clear="clearPrice('pickup')"
    />
    <ctk-input-address
      v-model="formData.deliveryString"
      :direction="'delivery'"
      :provider="provider"
      :disabled-countries="disabledCountries('delivery')"
      :label="$t('dashboard.labels.quotation.delivery_address')"
      :error="errors.delivery"
      :country.sync="countries.delivery"
      id="delivery"
      data-test="delivery-address"
      class="tw-mb-2"
      @click.native="initiate"
      @autocomplete="v => updateAddress('delivery', v)"
      @clear="clearPrice('delivery')"
    />

    <dashboard-express-quotation-form-goods
      v-model="formData.goods"
      ref="goods"
      data-test="goods"
      @click.native="initiate"
      @input="submitted"
    />
  </div>
</template>

<script>
  import Hotjar from '@/plugins/VueHotjar'
  import { mapActions, mapGetters } from 'vuex'

  import { availableCountries } from '@/../config/chronoinfos'
  import { showToaster } from '@/services/Toaster'
  import CtkInputAddress from '@/components/CtkInputs/CtkInputAddress'
  import EstimationAddressProvider from '@/providers/AddressProvider/EstimationAddressProvider'
  import DashboardExpressQuotationFormGoods from './_subs/DashboardExpressQuotationFormGoods'

  const defaultFormData = {
    pickup: null,
    pickupString: null,
    delivery: null,
    deliveryString: null,
    goods: null
  }

  /**
   * @module component - DashboardExpressQuotationForm
   */
  export default {
    name: 'DashboardExpressQuotationForm',
    components: {
      CtkInputAddress,
      DashboardExpressQuotationFormGoods
    },
    data () {
      return {
        provider: new EstimationAddressProvider(),
        hasMadeAction: false,
        formData: JSON.parse(JSON.stringify(defaultFormData)),
        errors: {
          pickup: null,
          delivery: null
        },
        countries: {
          pickup: 'FR',
          delivery: 'FR'
        }
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getEstimation'
      ]),
      /**
       * Returns a list of disabled countries according to either the pickup/delivery country
       * or the pickup/delivery address's country
       * @function disabledCountries
       * @returns {Array} countries
       */
      disabledCountries () {
        const unauthorizedSameCountries = availableCountries
          .filter(country => country['disabled-for-home'])
          .map(country => country['iso-2'])

        return direction => {
          const { pickup, delivery } = this.formData
          const isPickupAddressCountryUnauth = pickup && unauthorizedSameCountries.includes(pickup.country)
          const isPickupSelectedCountryUnauth = !!this.countries.pickup && unauthorizedSameCountries.includes(this.countries.pickup)

          const isDeliveryAddressCountryUnauth = delivery && unauthorizedSameCountries.includes(delivery.country)
          const isDeliverySelectedCountryUnauth = !!this.countries.delivery && unauthorizedSameCountries.includes(this.countries.delivery)

          if (direction === 'delivery' && (isPickupAddressCountryUnauth || isPickupSelectedCountryUnauth)) {
            return [isPickupAddressCountryUnauth ? pickup.country : this.countries.pickup]
          }

          if (direction === 'pickup' && (isDeliveryAddressCountryUnauth || isDeliverySelectedCountryUnauth)) {
            return [isDeliveryAddressCountryUnauth ? delivery.country : this.countries.delivery]
          }

          return []
        }
      }
    },
    methods: {
      ...mapActions('shipments', [
        'resetEstimation',
        'retrieveEstimation'
      ]),
      initiate () {
        if (!this.hasMadeAction) {
          this.hasMadeAction = true

          Hotjar.tag('Shipper Estimate')

          if (this.$matomo) {
            this.$matomo.trackEvent('Estimate', 'Initiate')
          }
        }
      },
      updateAddress (direction, address) {
        this.formData[direction] = address
        this.errors[direction] = null

        const { city, postalCode, country, hasRequiredComponents } = address.address

        /**
         * TODO: Find a better way to handle missing address components.
         * hasRequiredComponents is used when the entire address is required
         * but here only the city, country & postal code are required.
         */
        const hasEstimationComponents = !!city && !!postalCode && !!country
        if (hasRequiredComponents === false || !hasEstimationComponents) {
          this.errors[direction] = this.$t('app.paragraphs.error.address_not_precise')
        }

        this.submitted()
      },
      /**
       * Called whenever the user clears the pickup or the delivery address
       * @function clearPrice
       */
      clearPrice (direction) {
        this.formData[direction] = null
        this.formData[`${direction}String`] = null
        this.errors[direction] = null

        this.resetEstimation()
      },
      submitted () {
        const { pickup, delivery, goods } = this.formData
        const hasPickup = pickup && !!pickup.address &&
          !!pickup.address.postalCode && !!pickup.address.country && !!pickup.address.location
        const hasDelivery = delivery && !!delivery.address &&
          !!delivery.address.postalCode && !!delivery.address.country && !!delivery.address.location
        const hasGoods = !!goods

        if (!hasPickup || !hasDelivery || !hasGoods) return false

        this.$wait.start('fetching estimate price')
        this.retrieveEstimation({
          pickup: pickup,
          delivery: delivery,
          goods
        })
          .then(() => {
            if (this.$matomo) {
              const prices = this.getEstimation.prices && this.getEstimation.prices.map(price => price.type).join('|')
              this.$matomo.trackEvent('Estimate', 'Done', prices)
            }
          })
          .catch(async err => {
            if (!err.response) return

            const { data } = err.response
            if (data && data.error) {
              if (data.error.violations) {
                const loadViolations = data.error.violations
                  .filter(violation => violation.property_path.includes('load.'))

                /**
                 * If there is any violation in the load, open the dropdown to
                 * show the errors in the appropriate fields.
                 */
                if (loadViolations.length > 0) {
                  this.$refs.goods && this.$refs.goods.show()
                  await this.$nextTick()
                  loadViolations
                    .forEach(violation => {
                      const propertyPath = violation.property_path.replace('load.', '')
                      if (this.$refs.goods && this.$refs.goods.$refs.dropdown && this.$refs.goods.$refs.dropdown.$refs[`${propertyPath}-provider`]) {
                        this.$refs.goods.$refs.dropdown.$refs[`${propertyPath}-provider`].setErrors([
                          violation.message
                        ])
                      }
                    })
                }
              } else {
                const errorMessage = data.error.detail || data.error.title
                showToaster(this, errorMessage, {
                  type: 'error',
                  position: 'bottom-right'
                })
              }
            }
          })
          .finally(() => {
            this.$wait.end('fetching estimate price')
          })
      }
    }
  }
</script>
