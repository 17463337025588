<template>
  <dashboard-card
    :title="$t('dashboard.titles.quotation')"
    class="dashboard-express-quotation"
  >
    <div class="tw-flex tw-flex-col dashboard-express-quotation__wrapper">
      <dashboard-express-quotation-form
        :class="{
          'has-prices': getEstimation && getEstimation.prices && getEstimation.prices.length > 1
        }"
        class="tw-relative tw-flex-1"
        data-test="form"
      />
      <dashboard-express-quotation-estimate
        class="tw-flex-1"
        data-test="estimate"
      />
    </div>
  </dashboard-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import DashboardCard from './../DashboardCard/index.vue'
  import DashboardExpressQuotationForm from './_subs/DashboardExpressQuotationForm/index.vue'
  import DashboardExpressQuotationEstimate from './_subs/DashboardExpressQuotationEstimate/index.vue'

  /**
   * @module component - DashboardExpressQuotation
   */
  export default defineComponent({
    name: 'DashboardExpressQuotation',
    components: {
      DashboardCard,
      DashboardExpressQuotationForm,
      DashboardExpressQuotationEstimate
    },
    methods: {
      ...mapActions('shipments', [
        'resetEstimation'
      ])
    },
    mounted () {
      this.resetEstimation()
    },
    computed: {
      ...mapGetters('shipments', [
        'getEstimation'
      ])
    }
  })
</script>

<style lang="scss">

  .dashboard-express-quotation .dashboard-card__header {
    margin-bottom: 20px;
  }

</style>

<style lang="scss" scoped>

  .dashboard-express-quotation {
    .dashboard-express-quotation-form {
      margin-bottom: 50px;

      &::before {
        position: absolute;
        left: 0;
        content: '';
        bottom: -25px;
        width: 100%;
        height: 1px;
        background-color: $divider;
      }
    }
  }

</style>
