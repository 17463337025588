<template>
  <div class="dashboard-express-quotation-estimate-price tw-flex tw-flex-col tw-leading-snug">
    <div
      class="tw-flex tw-items-center"
    >
      <span
        class="dashboard-express-quotation-estimate-price__name tw-font-bold tw-text-base tw-mr-2"
        v-text="name"
        data-test="name"
      />
      <ui-material-icon
        v-if="info"
        class="dashboard-express-quotation-estimate-price__icon tw-hidden md:tw-inline-block tw-text-base tw-mb-1"
        v-b-tooltip.hover
        :title="info"
        name="info"
        data-test="info"
      />
    </div>
    <div
      class="tw-text-blue-500 tw-font-light tw-text-lg tw-text-3xl"
      v-text="$t('price_excl_tax', {
        price: $options.filters.currency(price, currency, $i18n.locale, true)
      })"
      data-test="price"
    />
  </div>
</template>

<script>
  /**
   * @module component - DashboardExpressQuotationEstimatePrice
   * @param {string} name
   * @param {string} currency - Currency code
   * @param {number} price - The price in cents
   * @param {string} [info=null] - The textual information in the tooltip
   */
  export default {
    name: 'DashboardExpressQuotationEstimatePrice',
    props: {
      name: {
        type: String,
        required: true
      },
      currency: {
        type: String,
        required: true
      },
      info: {
        type: String,
        default: null
      },
      price: {
        type: Number,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .dashboard-express-quotation-estimate-price {
    &__name,
    &__icon {
      color: $secondary-text;
    }
  }

</style>
