var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-billing-amounts tw-flex tw-rounded"},[_c('div',{staticClass:"dashboard-billing-amounts__wrapper tw-flex tw-items-center"},[[(_vm.loading)?_c('ctk-skeleton-view',{staticClass:"tw-flex tw-flex-col tw-mr-4 tw-w-full"},[_c('ctk-skeleton-block',{staticClass:"tw-mb-2",attrs:{"width":"50%","height":"20px","level":"3","loading":""}}),_c('ctk-skeleton-block',{attrs:{"width":"100%","height":"36px","level":"1","loading":""}})],1):_c('billing-invoice-amounts-header-section',{staticClass:"tw-mr-8",attrs:{"amount":_vm.$t('price_incl_tax', {
          price: _vm.$options.filters.currency(_vm.getInvoicesMetrics.amounts.payment_pending, 'EUR', this.$i18n.locale)
        })}},[_c('i18n',{attrs:{"path":"billing.labels.amount_to_pay","tag":"span"},scopedSlots:_vm._u([{key:"invoices",fn:function(){return [_c('span',{staticClass:"tw-font-medium",domProps:{"textContent":_vm._s(_vm.$tc('billing.labels.invoices', _vm.getInvoicesMetrics.counts.payment_pending, {
                count: _vm.getInvoicesMetrics.counts.payment_pending
              }))}})]},proxy:true}])})],1)],[(_vm.loading)?_c('ctk-skeleton-view',{staticClass:"tw-flex tw-flex-col tw-mr-4 tw-w-full"},[_c('ctk-skeleton-block',{staticClass:"tw-mb-2",attrs:{"width":"50%","height":"20px","level":"3","loading":""}}),_c('ctk-skeleton-block',{attrs:{"width":"100%","height":"36px","level":"1","loading":""}})],1):_c('billing-invoice-amounts-header-section',{attrs:{"amount":_vm.$t('price_incl_tax', {
          price: _vm.$options.filters.currency(_vm.getInvoicesMetrics.amounts.payment_late, 'EUR', this.$i18n.locale)
        }),"tooltip":_vm.$t('billing.paragraphs.payment_delay'),"primary":""}},[(_vm.getInvoicesMetrics && _vm.getInvoicesMetrics.counts)?_c('span',{directives:[{name:"markdown",rawName:"v-markdown",value:(_vm.$tc('billing.labels.amount_late', _vm.getInvoicesMetrics.counts.payment_late, {
            count: _vm.getInvoicesMetrics.counts.payment_late
          })),expression:"$tc('billing.labels.amount_late', getInvoicesMetrics.counts.payment_late, {\n            count: getInvoicesMetrics.counts.payment_late\n          })"}]}):_vm._e()])],(_vm.isBillingGrouped)?[(_vm.loading)?_c('ctk-skeleton-view',{staticClass:"tw-flex tw-flex-col tw-w-full"},[_c('ctk-skeleton-block',{staticClass:"tw-mb-2",attrs:{"width":"50%","height":"20px","level":"3","loading":""}}),_c('ctk-skeleton-block',{attrs:{"width":"100%","height":"36px","level":"1","loading":""}})],1):_c('billing-invoice-amounts-header-section',{staticClass:"tw-ml-8",attrs:{"amount":_vm.$t('price_incl_tax', {
          price: _vm.$options.filters.currency(_vm.getInvoicesMetrics.amounts.payment_proforma, 'EUR', this.$i18n.locale)
        }),"tooltip":_vm.$t('billing.paragraphs.outstanding_amount')}},[_c('span',{staticClass:"tw-font-medium",domProps:{"textContent":_vm._s(_vm.$t('billing.labels.amount_outstanding'))}})])]:_vm._e()],2),_c('ctk-skeleton-block',{staticClass:"dashboard-billing-amounts__explanation-wrapper tw-flex-1",attrs:{"loading":_vm.loading,"width":"100%","height":"64px","level":"3"}},[_c('billing-invoice-amounts-header-explanation',{attrs:{"tooltip":_vm.$t('billing.paragraphs.payment_conditions_dashboard')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }