<template>
  <dashboard-card
    :title="$t('dashboard.titles.your_shipments')"
    class="dashboard-shipments"
  >
    <div class="dashboard-shipments__states">
      <dashboard-shipments-state-available
        :loaded="hasMetrics"
        :count="hasMetrics
          ? getShipmentsMetrics.counts.available
          : null"
        class="tw-mb-4"
      />
      <dashboard-shipments-state-planned
        :loaded="hasMetrics"
        :count="hasMetrics
          ? getShipmentsMetrics.counts.planned
          : null"
        class="tw-mb-4"
      />
      <dashboard-shipments-state-in-progress
        :loaded="hasMetrics"
        :count="hasMetrics
          ? getShipmentsMetrics.counts.in_progress
          : null"
        class="tw-mb-4"
      />
    </div>
  </dashboard-card>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import DashboardCard from './../DashboardCard/index.vue'
  import DashboardShipmentsStateAvailable from './_subs/DashboardShipmentsStateAvailable/index.vue'
  import DashboardShipmentsStatePlanned from './_subs/DashboardShipmentsStatePlanned/index.vue'
  import DashboardShipmentsStateInProgress from './_subs/DashboardShipmentsStateInProgress/index.vue'

  /**
   * @module component - DashboardShipments
   */
  export default defineComponent({
    name: 'DashboardShipments',
    components: {
      DashboardCard,
      DashboardShipmentsStateAvailable,
      DashboardShipmentsStatePlanned,
      DashboardShipmentsStateInProgress
    },
    computed: {
      ...mapGetters('shipments', [
        'getShipmentsMetrics'
      ]),
      /**
       * @function hasMetrics
       * @returns {boolean}
       */
      hasMetrics () {
        return !this.$wait.is('fetching dashboard shipment metrics') &&
          !!this.getShipmentsMetrics && !!this.getShipmentsMetrics.counts
      }
    }
  })
</script>

<style lang="scss" scoped>

  .dashboard-shipments {
    position: relative;
  }

</style>

<style lang="scss">

  .dashboard-shipments .dashboard-card__header {
    margin-bottom: 20px;
  }

</style>
