<template>
  <div class="billing-invoice-amounts-header-explanation tw-rounded tw-flex tw-flex-col tw-justify-center tw-bg-white tw-p-6">
    <div
      class="tw-flex tw-items-center tw-mb-2"
      data-test="header"
    >
      <span
        v-text="$t('app.titles.payment_conditions')"
        class="tw-font-medium"
        data-test="title"
      />
      <ui-material-icon
        id="cc-infos"
        name="info"
        class="tw-text-base tw-ml-2"
        data-test="icon"
        @click.native="click"
      />

      <b-tooltip
        :disabled="tooltipOptions.disabled"
        target="cc-infos"
      >
        <div
          class="tw-text-left"
          v-text="tooltipOptions.title"
        />
      </b-tooltip>
    </div>

    <ul
      class="tw-p-0 tw-m-0"
      data-test="content"
    >
      <li
        v-for="(check, k) in checks"
        :key="k"
        class="tw-flex tw-items-center"
        data-test="check"
      >
        <ui-material-icon
          name="check"
          class="tw-text-base tw-mr-1"
        />
        <span
          v-text="check"
        />
      </li>
    </ul>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import useTooltipDialog from '@/composables/useTooltipDialog'
  import { i18n } from '@/locales'

  /**
   * @module component - BillingInvoiceAmountsHeaderExplanation
   * @param {string} tooltip
   */
  export default defineComponent({
    name: 'BillingInvoiceAmountsHeaderExplanation',
    props: {
      tooltip: {
        type: String,
        required: true
      }
    },
    setup () {
      const { click, haveDialog } = useTooltipDialog(null, i18n.t('billing.paragraphs.payment_conditions'))

      return {
        click,
        haveDialog
      }
    },
    computed: {
      ...mapGetters('auth', [
        'isCompanyPayingCC'
      ]),
      /**
       * @function tooltipOptions
       * @returns {{ title: string, disabled: boolean }}
       */
      tooltipOptions () {
        return {
          // @ts-ignore
          title: this.tooltip,
          disabled: this.haveDialog
        }
      },
      /**
       * @function checks
       * @returns {Array<string>}
       */
      checks () {
        if (this.isCompanyPayingCC) {
          return [
            // @ts-ignore
            this.$t('app.values.payment_conditions.cash'),
            // @ts-ignore
            this.$t('app.values.payment_conditions.cc')
          ]
        }

        return [
          // @ts-ignore
          this.$t('app.values.payment_conditions.thirty_days_invoice'),
          // @ts-ignore
          this.$t('app.values.payment_conditions.transfer_cc')
        ]
      }
    }
  })
</script>

<style lang="scss" scoped>

  .billing-invoice-amounts-header-explanation {
    border: 1px solid $divider;
  }

</style>
