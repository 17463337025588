<template>
  <dashboard-shipments-state
    :count="loaded ? $n(count) : '-'"
    :title="$tc(`dashboard.titles.shipments.available`, !loaded ? 0 : count)"
    state="available"
    class="dashboard-shipments-state-available tw-flex-1"
  >
    <shipments-state-item
      v-if="loaded && getShipmentsMetrics.counts.has_pending_proposals > 0"
      :title="$tc('dashboard.buttons.shipments_pending_proposals', getShipmentsMetrics.counts.has_pending_proposals, {
        count: $n(getShipmentsMetrics.counts.has_pending_proposals)
      })"
    />
  </dashboard-shipments-state>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import DashboardShipmentsState from './../DashboardShipmentsState/index.vue'
  import ShipmentsStateItem from './../DashboardShipmentsState/_subs/ShipmentsStateItem/index.vue'

  /**
   * @module component - DashboardShipmentsStateAvailable
   */
  export default defineComponent({
    name: 'DashboardShipmentsStateAvailable',
    props: {
      loaded: {
        type: Boolean,
        default: false
      },
      count: {
        type: Number,
        default: null
      }
    },
    components: {
      DashboardShipmentsState,
      ShipmentsStateItem
    },
    computed: {
      ...mapGetters('shipments', [
        'getShipmentsMetrics'
      ])
    }
  })
</script>

<style lang="scss" scoped>

  .dashboard-shipments-state-available {
    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
  }

</style>
