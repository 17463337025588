var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-shipments-state"},[_c('div',{staticClass:"tw-flex-1 dashboard-shipments-state__header-wrapper"},[_c('router-link',{staticClass:"dashboard-shipments-state__header tw-overflow-hidden tw-rounded tw-flex tw-flex-col tw-justify-center",class:{
        'dashboard-shipments-state__header--count': _vm.count > 0
      },attrs:{"to":{
        name: 'Shipments',
        params: {
          state: _vm.state
        }
      },"event":_vm.$wait.is('fetching dashboard shipment metrics') ? '' : 'click'}},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('ctk-skeleton-block',{staticClass:"tw-text-right tw-w-12 tw-mr-4",attrs:{"loading":_vm.$wait.is('fetching dashboard shipment metrics'),"width":"54px","height":"54px","level":"1"}},[_c('span',{staticClass:"dashboard-shipments-state__header__count tw-text-3xl tw-font-light",domProps:{"textContent":_vm._s(_vm.count)}})]),_c('ctk-skeleton-block',{staticClass:"dashboard-shipments-state__header__title-wrapper",attrs:{"loading":_vm.$wait.is('fetching dashboard shipment metrics'),"width":"125px","height":"54px","level":"2"}},[_c('span',{staticClass:"dashboard-shipments-state__header__title tw-text-base",domProps:{"textContent":_vm._s(_vm.title)}})])],1),_vm._t("default")],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }