<template>
  <div class="billing-invoice-amounts-header-section tw-flex-1 tw-flex tw-flex-col">
    <div class="tw-inline-flex tw-items-center tw-pr-2">
      <slot />
      <ui-material-icon
        v-if="tooltip"
        v-b-tooltip.hover.html="tooltipOptions"
        name="info"
        class="tw-text-base tw-ml-2"
        data-test="tooltip"
      />
    </div>
    <div
      :class="{
        'tw-text-yellow-500': primary
      }"
      class="billing-invoice-amounts-header-section__amount tw-text-2xl"
      v-text="amount"
      data-test="amount"
    />
  </div>
</template>

<script>
  import { defineComponent, toRefs } from '@vue/composition-api'

  import useTooltipDialog from '@/composables/useTooltipDialog'

  /**
   * @module component - BillingInvoiceAmountsHeaderSection
   * @param {string} amount
   * @param {string} [tooltip=null]
   * @param {boolean} [primary=false]
   */
  export default defineComponent({
    name: 'BillingInvoiceAmountsHeaderSection',
    props: {
      amount: {
        type: String,
        required: true
      },
      tooltip: {
        type: String,
        default: null
      },
      primary: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { tooltip } = toRefs(props)
      const { click, haveDialog } = useTooltipDialog(null, tooltip.value)

      return {
        click,
        haveDialog
      }
    },
    computed: {
      /**
       * @function tooltipOptions
       * @return {object}
       */
      tooltipOptions () {
        return {
          title: this.tooltip,
          disabled: this.haveDialog
        }
      }
    }
  })
</script>

<style lang="scss">
.billing-invoice-amounts-header-section .markdown p {
  margin-bottom: 0px;
}
.billing-invoice-amounts-header-section .markdown strong {
  font-weight: 500;
}
</style>
