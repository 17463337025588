var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-express-quotation-estimate tw-relative"},[_c('div',{staticClass:"dashboard-express-quotation-estimate__price tw-flex tw-flex-col md:tw-items-start md:tw-justify-between tw-mb-4",class:{
      'xl:tw-flex-row': _vm.hasPrice && _vm.getEstimation.prices.length > 1,
      'md:tw-flex-row': _vm.hasPrice && _vm.getEstimation.prices.length < 2
    }},[(_vm.$wait.is('fetching estimate price'))?[_c('ui-loader',{attrs:{"size":40,"background-back":"#000","type":"pallet"}})]:[(_vm.hasPrice)?[_c('div',{staticClass:"dashboard-express-quotation-estimate__prices tw-relative tw-flex tw-flex-col md:tw-flex-row tw-flex-1 tw-w-full",class:{
            'dashboard-express-quotation-estimate__prices--multiple': _vm.getEstimation.prices.length > 1
          }},_vm._l((_vm.getEstimation.prices),function(price){return _c('dashboard-express-quotation-estimate-price',{key:price.type,class:{
              'md:tw-w-1/2': _vm.getEstimation.prices.length > 1
            },attrs:{"name":_vm.offers[price.type],"price":price.total,"info":_vm.$t(price.type === 'pallet_network'
              ? 'dashboard.titles.quotation.info.pallet_network'
              : 'dashboard.titles.quotation.info.chronotruck'),"currency":price.currency}})}),1)]:_c('div',{staticClass:"dashboard-express-quotation-estimate__prices tw-flex tw-flex-col"},[_c('h3',{staticClass:"dashboard-express-quotation-estimate__title tw-font-bold tw-text-base tw-mr-2",domProps:{"textContent":_vm._s(_vm.$t('dashboard.titles.quotation.estimate'))}}),_c('div',{staticClass:"tw-text-blue-500 tw-font-light tw-text-lg tw-text-3xl",domProps:{"textContent":_vm._s(_vm.$t('price_excl_tax', {
            price: '- €'
          }))}})])],(_vm.hasPrice)?_c('ui-button',{staticClass:"tw-text-white tw-mt-4 xl:tw-mt-0",attrs:{"to":{
        name: 'NewShipmentAddress',
        params: {
          direction: 'pickup'
        }
      },"variant":"primary"},nativeOn:{"click":function($event){return _vm.refine.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.buttons.quotation.refine'))+" ")]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }