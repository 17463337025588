<template>
  <div class="dashboard-shipments-state">
    <div class="tw-flex-1 dashboard-shipments-state__header-wrapper">
      <router-link
        :to="{
          name: 'Shipments',
          params: {
            state: state
          }
        }"
        :class="{
          'dashboard-shipments-state__header--count': count > 0
        }"
        :event="$wait.is('fetching dashboard shipment metrics') ? '' : 'click'"
        class="dashboard-shipments-state__header tw-overflow-hidden tw-rounded tw-flex tw-flex-col tw-justify-center"
      >
        <div class="tw-flex tw-items-center">
          <ctk-skeleton-block
            :loading="$wait.is('fetching dashboard shipment metrics')"
            width="54px"
            height="54px"
            level="1"
            class="tw-text-right tw-w-12 tw-mr-4"
          >
            <span
              v-text="count"
              class="dashboard-shipments-state__header__count tw-text-3xl tw-font-light"
            />
          </ctk-skeleton-block>
          <ctk-skeleton-block
            :loading="$wait.is('fetching dashboard shipment metrics')"
            width="125px"
            height="54px"
            level="2"
            class="dashboard-shipments-state__header__title-wrapper"
          >
            <span
              v-text="title"
              class="dashboard-shipments-state__header__title tw-text-base"
            />
          </ctk-skeleton-block>
        </div>
        <slot />
      </router-link>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock/index.vue'

  /**
   * @module component - DashboardShipmentsState
   * @param {string} [count=null]
   * @param {string} title
   * @param {string} state
   */
  export default defineComponent({
    name: 'DashboardShipmentsState',
    components: {
      CtkSkeletonBlock
    },
    props: {
      count: {
        type: String,
        default: null
      },
      title: {
        type: String,
        required: true
      },
      state: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>
.dashboard-shipments-state {
  z-index: 1;
}
.dashboard-shipments-state__header {
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
  border: 1px solid $gray;
  padding: 12px 32px 8px 12px;
  transition: background-color 200ms, box-shadow 200ms, border 200ms;
  text-decoration: none;
  min-height: 80px;
}
.dashboard-shipments-state__header::before {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  content: '';
  width: 5px;
  background-color: $gray;
}
.dashboard-shipments-state__header__title {
  white-space: pre-line;
  margin: auto 0;
}
.dashboard-shipments-state__header__title-wrapper {
  line-height: 1.1em;
}
.dashboard-shipments-state__header:hover {
  border: 1px solid #F9F9F9;
  background-color: #F9F9F9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.dashboard-shipments-state__header--count {
  --tw-text-opacity: 1;
  color: rgba(33, 34, 46, var(--tw-text-opacity));
}
.dashboard-shipments-state__header--count::before {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
}
.dashboard-shipments-state__header--count .dashboard-shipments-state__header__count {
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
</style>
