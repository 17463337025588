<template>
  <button
    class="dashboard-express-quotation-form-goods-button text-left"
    role="button"
  >
    <div class="tw-flex tw-items-center">
      <div class="flex-fixed dashboard-express-quotation-form-goods-button__icon mr-2">
        <ui-ctk-icon
          name="dimension"
          data-test="icon"
        />
      </div>
      <div class="tw-flex-1">
        <template v-if="goods">
          <template
            v-if="goods.type === 'pallets'"
          >
            <span data-test="quantity">{{ $tc('dashboard.values.quotation.goods.pallet', goods.quantity, {
              count: goods.quantity
            }) }}</span>&nbsp;-&nbsp;<span data-test="format">{{ $t('dashboard.values.quotation.goods.format', {
              format: goods.format
            }) }}</span>&nbsp;-&nbsp;<span data-test="height">{{ $t('dashboard.values.quotation.goods.height', {
              height: $n(goods.height)
            }) }}</span>&nbsp;-&nbsp;<span data-test="weight">{{ $t('dashboard.values.quotation.goods.weight', {
              weight: $n(goods.weight)
            }) }}</span>
          </template>
          <template
            v-else
          >
            <span data-test="format">{{ $t('dashboard.values.quotation.goods.format', {
              format: `${goods.width}x${goods.length}x${goods.height}`
            }) }}</span>&nbsp;-&nbsp;<span data-test="weight">{{ $t('dashboard.values.quotation.goods.weight', {
              weight: $n(goods.weight)
            }) }}</span>
          </template>
        </template>
        <template
          v-else
        >
          <span class="dashboard-express-quotation-form-goods-button__label">
            {{ $t('dashboard.labels.quotation.goods') }}
          </span>
        </template>
      </div>
    </div>
  </button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - DashboardExpressQuotationFormGoodsButton
   * @param {object} goods
   */
  export default defineComponent({
    name: 'DashboardExpressQuotationFormGoodsButton',
    props: {
      goods: {
        type: Object,
        default: null
      }
    }
  })
</script>

<style lang="scss" scoped>

  .dashboard-express-quotation-form-goods-button {
    position: relative;
    width: 100%;
    height: 42px;
    min-height: 42px;
    padding: 0 12px;
    font-weight: 400;
    appearance: none;
    outline: none;
    background-color: white;
    border: 1px solid #D2D2D2;
    border-radius: 4px;

    &__icon {
      color: $info;
    }

    &__label {
      color: $secondary-text;
    }

    &:focus:not(:disabled) {
      border-color: $info;
    }
  }

</style>
