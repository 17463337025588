<template>
  <dashboard-shipments-state
    :count="loaded ? $n(count) : '-'"
    :title="$tc(`dashboard.titles.shipments.planned`, !loaded ? 0 : count)"
    state="planned"
    class="dashboard-shipments-state-planned"
  />
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import DashboardShipmentsState from './../DashboardShipmentsState/index.vue'

  /**
   * @module component - DashboardShipmentsStatePlanned
   */
  export default defineComponent({
    name: 'DashboardShipmentsStatePlanned',
    props: {
      loaded: {
        type: Boolean,
        default: false
      },
      count: {
        type: Number,
        default: null
      }
    },
    components: {
      DashboardShipmentsState
    }
  })
</script>
