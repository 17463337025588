<template>
  <div class="dashboard">
    <div class="ctk-container">
      <h1
        v-text="$t('dashboard.titles.main')"
        class="dashboard__title tw-mb-4"
        data-test="title"
      />
      <dashboard-statistics
        class="tw-mb-4"
      />
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-mb-4">
        <dashboard-shipments
          class="tw-mb-4 md:tw-mb-0 md:tw-mr-4 md:tw-w-5/12"
        />
        <dashboard-express-quotation
          class="md:tw-w-7/12"
        />
      </div>
      <dashboard-offers
        class="tw-mb-4"
      />
      <dashboard-billing
        class="tw-mb-8"
      />
      <new-shipment-quotation-compare-dialog
        v-model="dialogs.compare"
        :has-price="false"
        data-test="compare-dialog"
      />
      <new-shipment-quotation-learn-more-dialog
        v-model="dialogs.learnMore.visible"
        :price-type="dialogs.learnMore.priceType"
        data-test="learn-more-dialog"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import store from '@/store'
  import { loadLanguageAsync, i18n } from '@/locales'
  import { EventBus } from '@/services/EventBus'

  import DashboardStatistics from './components/DashboardStatistics/index.vue'
  import DashboardShipments from './components/DashboardShipments/index.vue'
  import DashboardBilling from './components/DashboardBilling/index.vue'
  import DashboardOffers from './components/DashboardOffers/index.vue'
  import DashboardExpressQuotation from './components/DashboardExpressQuotation/index.vue'
  import NewShipmentQuotationCompareDialog from '@/views/Shippers/NewShipment/_subs/NewShipmentQuotation/components/NewShipmentQuotationCompareDialog/index.vue'
  import NewShipmentQuotationLearnMoreDialog from '@/views/Shippers/NewShipment/_subs/NewShipmentQuotation/components/NewShipmentQuotationLearnMoreDialog/index.vue'

  /**
   * @module component - Dashboard
   */
  export default defineComponent({
    name: 'Dashboard',
    components: {
      DashboardStatistics,
      DashboardShipments,
      DashboardBilling,
      DashboardOffers,
      DashboardExpressQuotation,
      NewShipmentQuotationCompareDialog,
      NewShipmentQuotationLearnMoreDialog
    },
    // @ts-ignore
    metaInfo () {
      return {
        title: this.$t('dashboard.titles.main')
      }
    },
    // @ts-ignore
    async beforeRouteEnter (to, from, next) {
      await loadLanguageAsync(i18n.locale, ['billing', 'shipments', 'new-shipment'])

      if (!store.getters.isUserShipper) {
        next({
          name: 'Offers'
        })
        return
      }

      store.dispatch('setAppReady', true)
      next()
    },
    data () {
      return {
        dialogs: {
          compare: false,
          learnMore: {
            visible: false,
            priceType: 'pallet_network'
          }
        }
      }
    },
    computed: {
      ...mapGetters('billing', [
        'getInvoicesMetrics'
      ])
    },
    methods: {
      ...mapActions('shipments', [
        'retrieveShipmentsMetrics',
        'retrieveDashboardMetrics'
      ]),
      ...mapActions('billing', [
        'retrieveInvoicesMetrics'
      ]),
      fetchMetrics () {
        this.$wait.start('fetching dashboard shipment metrics')
        this.retrieveShipmentsMetrics()
          .finally(() => this.$wait.end('fetching dashboard shipment metrics'))

        this.$wait.start('fetching dashboard billing metrics')
        this.retrieveInvoicesMetrics()
          .finally(() => this.$wait.end('fetching dashboard billing metrics'))

        this.$wait.start('fetching dashboard statistics')
        this.retrieveDashboardMetrics({})
          .finally(() => this.$wait.end('fetching dashboard statistics'))
      }
    },
    mounted () {
      this.fetchMetrics()

      EventBus.$on('dashboard:dialogs:open-dialog', (/** @type {{
        dialog: string,
        priceType: string?
      }} */ { dialog, priceType }) => {
        if (dialog === 'learnMore' && priceType) {
          this.dialogs[dialog] = {
            visible: true,
            priceType
          }
        } else {
          // @ts-ignore
          this.dialogs[dialog] = true
        }
      })
    },
    beforeDestroy () {
      EventBus.$off('dashboard:dialogs:open-dialog')
    }
  })
</script>

<style lang="scss" scoped>

  .dashboard {
    padding: 32px 0 64px 0;
    overflow-y: scroll !important;

    &__title {
      font-size: 22px;
      font-weight: 500;
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      padding: 16px;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      padding: 16px;
    }
  }

</style>
