<template>
  <dashboard-card
    :title="$t('dashboard.titles.billing')"
    class="dashboard-billing tw-relative"
  >
    <template
      v-if="!$wait.is('fetching dashboard billing metrics')"
      #header-buttons
    >
      <div>
        <ui-button
          :to="{
            name: 'Billing'
          }"
          :rounded="false"
          variant="info"
          data-test="view-invoices"
        >
          {{ $t('dashboard.buttons.all_invoices') }}
        </ui-button>
      </div>
    </template>

    <dashboard-billing-amounts
      :loading="!hasMetrics"
    />
  </dashboard-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DashboardCard from './../DashboardCard'

  import DashboardBillingAmounts from './_subs/DashboardBillingAmounts'

  /**
   * @module component - DashboardBilling
   */
  export default {
    name: 'DashboardBilling',
    components: {
      DashboardCard,
      DashboardBillingAmounts
    },
    computed: {
      ...mapGetters('billing', [
        'getInvoicesMetrics'
      ]),
      /**
       * @function hasMetrics
       * @returns {boolean}
       */
      hasMetrics () {
        return !this.$wait.is('fetching dashboard billing metrics') &&
          !!this.getInvoicesMetrics && !!this.getInvoicesMetrics.counts
      }
    }
  }
</script>

<style lang="scss" scoped>

  .dashboard-billing {
    @media only screen and (min-width: $breakpoint-tablet) {
      padding-bottom: 240px;

      &::after {
        content: '';
        position: absolute;
        bottom: 30px;
        left: 40px;
        width: calc(100% - 80px);
        height: 210px;
        background-image: url('~@/assets/img/illustrations/shipment_confirmation_large.svg');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        user-select: none;
      }
    }
  }

</style>
