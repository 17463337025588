<template>
  <div class="dashboard-card tw-bg-white tw-rounded">
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between dashboard-card__header">
      <slot name="header-title">
        <div class="tw-flex tw-items-center">
          <h2
            class="dashboard-card__header__title tw-mb-4"
            v-text="title"
          />
          <ui-material-icon
            v-if="tooltip"
            v-b-tooltip.hover.html="tooltipOptions"
            name="info"
            class="tw-text-base tw-align-middle tw-ml-1 tw-mb-4"
            @click.native="click"
            data-test="tooltip"
          />
        </div>
      </slot>
      <slot name="header-buttons" />
    </div>
    <div class="dashboard-card__content">
      <slot />
    </div>
  </div>
</template>

<script>
  import { defineComponent, toRefs, computed } from '@vue/composition-api'

  import useTooltipDialog from '@/composables/useTooltipDialog'

  /**
   * @module component - DashboardCard
   * @param {string} title
   * @param {string} [tooltip=null]
   */
  export default defineComponent({
    name: 'DashboardCard',
    props: {
      title: {
        type: String,
        default: null
      },
      tooltip: {
        type: String,
        default: null
      }
    },
    setup (props) {
      const { tooltip } = toRefs(props)
      const { click, haveDialog } = useTooltipDialog(null, tooltip.value)

      const tooltipOptions = computed(() => ({
        title: tooltip.value,
        disabled: haveDialog.value
      }))

      return {
        click,
        haveDialog,
        tooltipOptions
      }
    }
  })
</script>

<style lang="scss" scoped>

  .dashboard-card {
    padding: 30px 40px;
    border: 1px solid $divider;

    &__header {
      &__title {
        position: relative;
        font-size: 20px;
        font-weight: 400;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -4px;
          width: 200px;
          height: 1px;
          background-color: $divider;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      padding: 16px;

      &__header__title::after {
        width: 100%;
      }
    }
  }

</style>
