<template>
  <div
    :class="{
      'has-error': hasError
    }"
    class="dashboard-express-quotation-form-goods-dropdown-help tw-flex"
    v-b-tooltip.hover.html="tooltip"
    @click="click"
  >
    <ui-material-icon
      name="info"
      class="tw-m-auto tw-text-base"
      data-test="icon"
    />
  </div>
</template>

<script>
  import { defineComponent, toRefs } from '@vue/composition-api'

  import useTooltipDialog from '@/composables/useTooltipDialog'

  /**
   * @module component - DashboardExpressQuotationFormGoodsDropdownHelp
   * @param {string} title - Title to be shown as the header of the tooltip
   * @param {string} content - Content of the tooltip
   * @param {boolean} hasError
   */
  export default defineComponent({
    name: 'DashboardExpressQuotationFormGoodsDropdownHelp',
    props: {
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        required: true
      },
      hasError: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { title, content } = toRefs(props)
      const { click, haveDialog } = useTooltipDialog(title.value, content.value)

      return {
        click,
        haveDialog
      }
    },
    computed: {
      /**
       * @function tooltip
       * @return {{ title: string, disabled: boolean }}
       */
      tooltip () {
        return {
          title: `<div>
            <div class="tw-text-xs tw-text-white tw-text-opacity-75">${this.title}</div>
            <p class="tw-mb-0">
              ${this.content}
            </p>
          </div>`,
          disabled: this.haveDialog
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  .dashboard-express-quotation-form-goods-dropdown-help {
    color: $secondary-text;
    padding: 0 10px;

    &.has-error {
      color: $danger;
    }
  }

</style>
