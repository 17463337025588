<template>
  <div
    class="shipments-state-item tw-flex tw-items-center tw-ml-6 tw-pt-1"
  >
    <span class="tw-flex tw-items-center">
      <div
        v-text="title"
        class="shipments-state-item__title"
      />
      <ui-material-icon
        name="info"
        class="shipments-state-item__help tw-text-sm tw-ml-2 tw-mt-1"
        v-b-tooltip.hover
        :title="$t('dashboard.paragraphs.proposal_explanation')"
      />
    </span>

    <div
      class="shipments-state-item__proposals tw-absolute tw-bottom-0 tw-right-0 tw-overflow-hidden"
      data-test="proposal"
    >
      <ui-ctk-icon
        name="bid-2"
        class="tw-text-white"
        data-test="icon"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - ShipmentsStateItem
   * @param {string} title
   */
  export default defineComponent({
    name: 'ShipmentsStateItem',
    inheritAttrs: false,
    props: {
      title: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipments-state-item {
    border-top: 1px solid $gray;
    color: $secondary-text;

    &__help {
      font-size: 1rem;
    }

    &__title {
      line-height: 1em;
    }

    &__proposals {
      $triangle-size: 40px;

      border-bottom-left-radius: 4px;
      height: $triangle-size;
      width: $triangle-size;
      right: 0;
      bottom: 0;

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0;
        left: 0;
        border-style: solid;
        border-width: 0 0 $triangle-size $triangle-size;
        border-color: transparent transparent $proposal-pending transparent;
        z-index: 0;
      }

      .ctk-font {
        font-size: 26px;
        position: absolute;
        bottom: 0;
        right: -3px;
        z-index: 1;
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &__help {
        display: none;
      }
    }
  }

</style>
