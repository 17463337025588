<template>
  <div class="dashboard-express-quotation-estimate tw-relative">
    <div
      :class="{
        'xl:tw-flex-row': hasPrice && getEstimation.prices.length > 1,
        'md:tw-flex-row': hasPrice && getEstimation.prices.length < 2
      }"
      class="dashboard-express-quotation-estimate__price tw-flex tw-flex-col md:tw-items-start md:tw-justify-between tw-mb-4"
    >
      <template
        v-if="$wait.is('fetching estimate price')"
      >
        <ui-loader
          :size="40"
          background-back="#000"
          type="pallet"
          data-test="loader"
        />
      </template>
      <template
        v-else
      >
        <template
          v-if="hasPrice"
        >
          <div
            :class="{
              'dashboard-express-quotation-estimate__prices--multiple': getEstimation.prices.length > 1
            }"
            class="dashboard-express-quotation-estimate__prices tw-relative tw-flex tw-flex-col md:tw-flex-row tw-flex-1 tw-w-full"
            data-test="prices"
          >
            <dashboard-express-quotation-estimate-price
              v-for="price in getEstimation.prices"
              :key="price.type"
              :name="offers[price.type]"
              :price="price.total"
              :info="$t(price.type === 'pallet_network'
                ? 'dashboard.titles.quotation.info.pallet_network'
                : 'dashboard.titles.quotation.info.chronotruck')"
              :currency="price.currency"
              :data-test="price.type"
              :class="{
                'md:tw-w-1/2': getEstimation.prices.length > 1
              }"
            />
          </div>
        </template>
        <div
          v-else
          class="dashboard-express-quotation-estimate__prices tw-flex tw-flex-col"
          data-test="empty"
        >
          <h3
            class="dashboard-express-quotation-estimate__title tw-font-bold tw-text-base tw-mr-2"
            data-test="empty-title"
            v-text="$t('dashboard.titles.quotation.estimate')"
          />

          <div
            class="tw-text-blue-500 tw-font-light tw-text-lg tw-text-3xl"
            v-text="$t('price_excl_tax', {
              price: '- €'
            })"
            data-test="empty-price"
          />
        </div>
      </template>

      <ui-button
        v-if="hasPrice"
        :to="{
          name: 'NewShipmentAddress',
          params: {
            direction: 'pickup'
          }
        }"
        class="tw-text-white tw-mt-4 xl:tw-mt-0"
        variant="primary"

        data-test="refine-button"
        @click.native="refine"
      >
        {{ $t('dashboard.buttons.quotation.refine') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import { offers } from '@/../config/chronoinfos'

  import DashboardExpressQuotationEstimatePrice from './_subs/DashboardExpressQuotationEstimatePrice'

  /**
   * @module component - DashboardExpressQuotationEstimate
   */
  export default {
    name: 'DashboardExpressQuotationEstimate',
    components: {
      DashboardExpressQuotationEstimatePrice
    },
    data () {
      return {
        offers
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getEstimation',
        'getExpressQuoteAddress',
        'getExpressQuoteLoad'
      ]),
      /**
       * Returns true if the quotation has at least one price
       * @function hasPrice
       * @returns {boolean}
       */
      hasPrice () {
        return this.getEstimation && this.getEstimation.prices && this.getEstimation.prices.length
      }
    },
    methods: {
      ...mapActions('shipments/new-shipment', [
        'setAddress',
        'setGoods',
        'setPallets',
        'setContact',
        'setComment',
        'setTimeslot',
        'setHandlingDriver',
        'setHandlingTailLift'
      ]),
      refine () {
        if (this.$matomo) {
          const prices = this.getEstimation.prices && this.getEstimation.prices.map(price => price.type).join('|')
          this.$matomo.trackEvent('Estimate', 'Transform In Quotation', prices)
        }

        const load = this.getExpressQuoteLoad
        if (load) {
          const { quantity, format, length, width } = load
          this.setGoods(load)
          this.setPallets([
            {
              quantity,
              format,
              length,
              width
            }
          ])

          /**
           * Check if we have a full address from the estimation form.
           * If we have, then set it.
           * Otherwise, set only the country
           */
          const directions = ['pickup', 'delivery']
          directions.forEach(direction => {
            const directionData = this.getExpressQuoteAddress[direction]
            if (directionData.address.streetName) {
              const {
                companyName,
                source,
                address
              } = directionData

              const {
                streetName,
                city,
                postalCode,
                country,
                location
              } = address

              const hasCompanyName = !!companyName

              const addressToSave = {
                name: companyName,
                street_name: streetName || companyName,
                postal_code: postalCode,
                city,
                country,
                location: {
                  lat: location.lat,
                  lng: location.lng
                },
                hadCustomName: !hasCompanyName
              }

              /**
               * Go fetch the contact information inside the source object, since
               * the "address" here is an AddressComponent.
               */
              if (source && source.address && source.address.contact) {
                addressToSave.contact = source.address.contact
              }

              this.setAddress({
                direction,
                address: addressToSave
              })

              /**
               * Check if the source is present (in the case of a Ctk address).
               * Otherwise ignore.
               */
              if (directionData.source) {
                const {
                  comment,
                  contact,
                  handling,
                  start_time: startTime,
                  end_time: endTime
                } = directionData.source

                if (contact) {
                  this.setContact({
                    direction,
                    contact
                  })
                }

                this.setComment({
                  direction,
                  comment
                })

                if (handling) {
                  /**
                   * TODO: Change this behaviour and use a boolean instead.
                   */
                  this.setHandlingDriver({
                    direction: direction,
                    value: handling.driver ? 'carrier' : 'shipper'
                  })
                  this.setHandlingTailLift({
                    direction: direction,
                    value: handling.tail_lift ? 'yes' : 'no'
                  })
                }

                if (startTime && endTime) {
                  this.setTimeslot({
                    direction: direction,
                    startTime: this.$moment.duration(startTime).asMinutes(),
                    endTime: this.$moment.duration(endTime).asMinutes()
                  })
                }
              }
            } else {
              /**
               * Set only the country from the estimation to the shipment form
               * since we only have this data available yet.
               */
              this.setAddress({
                direction,
                address: {
                  country: directionData.address.country
                }
              })
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.dashboard-express-quotation-estimate__title {
  color: $secondary-text;
}
.dashboard-express-quotation-estimate .dashboard-express-quotation-estimate-price:not(:last-child) {
  border-bottom: 1px solid $divider;
  padding-bottom: 1.375rem;
  margin-bottom: 1.375rem;
}
@media (min-width: 770px) {
  .dashboard-express-quotation-estimate .dashboard-express-quotation-estimate-price:not(:last-child) {
    border-bottom: none;
    border-right: 1px solid $divider;
    margin: 0;
    padding: 0;
    padding-right: 30px;
  }
  .dashboard-express-quotation-estimate .dashboard-express-quotation-estimate__prices--multiple .dashboard-express-quotation-estimate-price:last-child {
    padding-left: 30px;
  }
}
.dashboard-express-quotation-estimate__prices::after {
  position: absolute;
  content: '';
  top: -35px;
  left: 35px;
  width: 20px;
  height: 20px;
  transform: rotate(135deg);
  background-color: white;
  border-top: 1px solid $divider;
  border-right: 1px solid $divider;
}
@media (min-width: 770px) {
  .dashboard-express-quotation-estimate__prices--multiple::after {
    left: 0;
    right: 0;
    margin: auto;
  }
}
</style>
