var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{staticClass:"dashboard-express-quotation-form-goods-dropdown",on:{"submit":function($event){$event.preventDefault();return _vm.confirm.apply(null, arguments)},"keydown":_vm.checkHide}},[_c('ValidationProvider',{ref:"type-provider",attrs:{"name":_vm.$t('dashboard.fields.quotation.goods.type'),"rules":'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-select',{staticClass:"mb-3",attrs:{"label":_vm.$t('dashboard.labels.quotation.goods.type'),"items":_vm.types,"error":invalid && validated || errors && errors.length > 0,"id":"type","name":"type","required":""},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})]}}])}),(_vm.formData.type === 'pallets' || _vm.formData.type === null)?[_c('ValidationProvider',{ref:"quantity-provider",attrs:{"name":_vm.$t('app.labels.load.quantity'),"rules":("required|min_value:1|max_value:" + _vm.maxQuantity),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-select',{staticClass:"tw-flex-1 mb-2",attrs:{"label":_vm._f("capitalize")(_vm.$t('app.labels.load.quantity')),"items":_vm.quantities,"value-to-show":_vm.formData.quantity,"error":invalid && validated || errors && errors.length > 0,"disabled":_vm.formData.type === null,"id":"quantity","name":"quantity","required":""},model:{value:(_vm.formData.quantity),callback:function ($$v) {_vm.$set(_vm.formData, "quantity", $$v)},expression:"formData.quantity"}})]}}],null,false,1265455747)}),_c('ValidationProvider',{ref:"format-provider",attrs:{"name":_vm.$t('app.labels.load.format'),"rules":'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-select',{staticClass:"tw-flex-1 mb-2",attrs:{"label":_vm._f("capitalize")(_vm.$t('app.labels.load.format')),"items":_vm.formats,"value-to-show":_vm.formData.format,"error":invalid && validated || errors && errors.length > 0,"disabled":_vm.formData.type === null,"id":"format","name":"format","required":""},model:{value:(_vm.formData.format),callback:function ($$v) {_vm.$set(_vm.formData, "format", $$v)},expression:"formData.format"}})]}}],null,false,1441411702)})]:[_c('ValidationProvider',{ref:"length-provider",staticClass:"tw-flex mb-2",attrs:{"name":_vm.$t('dashboard.fields.quotation.goods.length'),"rules":'required|min_value:1|max_value:1320|numeric',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-flex-1",attrs:{"id":"length","error":invalid && validated || errors && errors.length > 0,"label":_vm.$t('dashboard.labels.quotation.goods.length'),"disabled":_vm.formData.type === null,"name":"length","type":"tel","suffix":"cm","required":""},model:{value:(_vm.formData.length),callback:function ($$v) {_vm.$set(_vm.formData, "length", $$v)},expression:"formData.length"}}),_c('dashboard-express-quotation-form-goods-dropdown-help',{attrs:{"has-error":invalid && validated || errors && errors.length > 0,"title":_vm.$t('dashboard.labels.quotation.goods.length'),"content":_vm.$t('dashboard.paragraphs.quotation.length_info')}})]}}])}),_c('ValidationProvider',{ref:"width-provider",staticClass:"tw-flex mb-2",attrs:{"name":_vm.$t('dashboard.fields.quotation.goods.width'),"rules":'required|min_value:1|max_value:240|numeric',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-flex-1",attrs:{"id":"width","error":invalid && validated || errors && errors.length > 0,"label":_vm.$t('dashboard.labels.quotation.goods.width'),"disabled":_vm.formData.type === null,"name":"width","type":"tel","suffix":"cm","required":""},model:{value:(_vm.formData.width),callback:function ($$v) {_vm.$set(_vm.formData, "width", $$v)},expression:"formData.width"}}),_c('dashboard-express-quotation-form-goods-dropdown-help',{attrs:{"has-error":invalid && validated || errors && errors.length > 0,"title":_vm.$t('dashboard.labels.quotation.goods.width'),"content":_vm.$t('dashboard.paragraphs.quotation.width_info')}})]}}])})],_c('ValidationProvider',{ref:"height-provider",staticClass:"tw-flex mb-2",attrs:{"name":_vm.$t('app.labels.load.max_height'),"rules":'required|min_value:1|max_value:270|numeric',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-flex-1",attrs:{"id":"height","error":invalid && validated || errors && errors.length > 0,"label":_vm._f("capitalize")(_vm.$t('app.labels.load.max_height')),"disabled":_vm.formData.type === null,"name":"height","type":"tel","suffix":"cm","required":""},model:{value:(_vm.formData.height),callback:function ($$v) {_vm.$set(_vm.formData, "height", $$v)},expression:"formData.height"}}),_c('dashboard-express-quotation-form-goods-dropdown-help',{attrs:{"has-error":invalid && validated || errors && errors.length > 0,"title":_vm._f("capitalize")(_vm.$t('app.labels.load.max_height')),"content":_vm.$t(_vm.formData.type === 'pallets'
            ? 'dashboard.paragraphs.quotation.height_info_pallet'
            : 'dashboard.paragraphs.quotation.height_info_custom')}})]}}])}),_c('ValidationProvider',{ref:"weight-provider",staticClass:"tw-flex mb-2",attrs:{"name":_vm.$t('dashboard.fields.quotation.goods.weight'),"rules":'required|min_value:50|max_value:28000|numeric',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
            var validated = ref.validated;
            var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-flex-1",attrs:{"id":"weight","error":invalid && validated || errors && errors.length > 0,"label":_vm.$t('dashboard.labels.quotation.goods.weight'),"disabled":_vm.formData.type === null,"name":"weight","type":"tel","suffix":"kg","required":""},model:{value:(_vm.formData.weight),callback:function ($$v) {_vm.$set(_vm.formData, "weight", $$v)},expression:"formData.weight"}}),_c('dashboard-express-quotation-form-goods-dropdown-help',{attrs:{"has-error":invalid && validated || errors && errors.length > 0,"title":_vm.$t('dashboard.labels.quotation.goods.weight'),"content":_vm.$t('dashboard.paragraphs.quotation.weight_info')}})]}}])}),_c('div',{staticClass:"tw-flex tw-items-end pt-4"},[_c('ui-button',{staticClass:"dashboard-express-quotation-form-goods-dropdown__validate",attrs:{"title":_vm._f("capitalize")(_vm.$t('validate')),"disabled":_vm.$wait.is('fetching estimate price'),"loading":_vm.$wait.is('fetching estimate price'),"type":"submit","variant":"primary"},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('ui-material-icon',{attrs:{"name":"check"}})]},proxy:true}])})],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }