<template>
  <div
    class="dashboard-offers-item tw-flex tw-flex-col tw-justify-between tw-text-center tw-items-center tw-py-8 tw-px-5 tw-rounded tw-relative"
  >
    <div>
      <img
        :src="icon"
        alt=""
        width="65"
        height="54"
        data-test="icon"
        class="tw-mb-3"
      >
      <h3
        v-text="title"
        class="dashboard-offers-item__title tw-mb-4 tw-text-blue-500 tw-text-base tw-font-bold tw-relative"
        data-test="title"
      />
      <p
        v-text="content"
        class="dashboard-offers-item__content tw-mb-10"
        data-test="content"
      />

      <offer-reassurance-list
        :offer-type="offerType"
        class="tw-mb-8"
        data-test="reassurance"
      />
    </div>

    <ui-button
      variant="info"
      size="sm"
      data-test="learn-more"
      @click="learnMore"
    >
      {{ $t('dashboard.buttons.offers.learn_more') }}
    </ui-button>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import OfferReassuranceList from '@/views/Shippers/components/OfferReassuranceList/index.vue'

  /**
   * @module component - DashboardOffersItem
   * @param {object} icon
   * @param {string} offerType
   * @param {string} title
   * @param {string} content
   */
  export default defineComponent({
    name: 'DashboardOffersItem',
    components: {
      OfferReassuranceList
    },
    props: {
      icon: {
        type: [Object, String],
        required: true
      },
      offerType: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        required: true
      }
    },
    methods: {
      learnMore () {
        this.$emit('learn-more')
      }
    }
  })
</script>

<style lang="scss" scoped>

  .dashboard-offers-item {
    border: 1px solid $gray;

    &__title {
      &::after {
        content: '';
        position: absolute;
        bottom: -0.25rem;
        left: 0;
        right: 0;
        margin: auto;
        height: 1px;
        width: 135px;
        background-color: $info;
      }
    }

    &__content {
      color: $secondary-text;
    }
  }

</style>
